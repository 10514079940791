<template>
  <section class="medico__import">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card title="Importar Médicos">
          <b-row>
            <b-col cols="4">
              <p class="card-description white-mode__black">
                Siga os passos abaixo para realizar a importação:
              </p>
              <ul class="list-arrow">
                <li>
                  <span class="white-mode__black">Baixe o arquivo modelo:</span>
                </li>
                <li>
                  <a :href="arquivoImportacaoOdsUrl">ODS</a>
                  <span class="white-mode__black">|</span>
                  <a :href="arquivoImportacaoXlsxUrl">XLSX</a>
                  <span class="white-mode__black">|</span>
                  <a :href="arquivoImportacaoCsvUrl">CSV</a>
                </li>
                <li>
                  <span class="white-mode__black">Preencha as colunas correspondentes</span>
                </li>
                <li>
                  <span class="white-mode__black">Atributos multivalorados como telefones e emails podem ser
                    separados por linhas, virgula ou "/"</span>
                </li>
                <li>
                  <span class="white-mode__black">Salve o arquivo e envie ao lado</span>
                </li>
              </ul>
            </b-col>
            <b-col cols="8">
              <form>
                <b-form-group label="Envie seu arquivo" label-for="arquivo" description="Tamanho Máximo: 64 MB">
                  <b-form-file @input="uploadCsv()" v-model="arquivo" id="arquivo" :state="Boolean(arquivo)"
                    accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
                    placeholder="Selecione ou arraste um arquivo aqui..."
                    drop-placeholder="Arraste o arquivo aqui..."></b-form-file>
                </b-form-group>
              </form>
              <div v-if="importing" class="circle-loader"></div>
            </b-col>
          </b-row>
          <b-table show-empty stacked="md" :items="items" :fields="fields" select-mode="multi" selected-variant="dark"
            responsive :busy="loadingTable">
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>
            <template #cell(erros)="row">
              <template v-if="row.item.erros > 0">
                <b-badge :id="'import-popover-' + row.item.id" href="#" pill variant="danger">{{ row.item.erros
                  }}</b-badge>
                <b-popover :target="'import-popover-' + row.item.id" variant="danger" triggers="hover" container="body">
                  <template #title>Erros Encontrados</template>
                  <span style="white-space: pre-line">Realize o download do arquivo com erros, ajuste os dados
                    necessários e importe
                    novamente.
                  </span><br><br>
                  <b-button variant="primary" class="mr-1" title="Visualizar" @click="downloadErros(row.item.id, row.item.arquivo)">
                      Download
                  </b-button>
                </b-popover>
              </template>
              <template v-else>
                <b-badge pill variant="primary">{{ row.item.erros }}</b-badge>
              </template>
            </template>
            <template #cell(processado)="row">
              {{ row.value == 0 ? "Pendente" : "Finalizado" }}
            </template>
            <template #cell(created_at)="row">
              {{ row.value | formatDateTime }}
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import ImportService from "@/services/import.service";

export default {
  name: "MedicoImport",
  data() {
    return {
      arquivoImportacaoOdsUrl:
        process.env.VUE_APP_API_URL +
        "/importacao/piattino_medicos_importacao.ods",
      arquivoImportacaoXlsxUrl:
        process.env.VUE_APP_API_URL +
        "/importacao/piattino_medicos_importacao.xlsx",
      arquivoImportacaoCsvUrl:
        process.env.VUE_APP_API_URL +
        "/importacao/piattino_medicos_importacao.csv",
      fields: [
        {
          label: "Nome",
          key: "arquivo",
        },
        {
          key: "novos",
        },
        {
          key: "atualizados",
        },
        {
          key: "erros",
        },
        {
          label: "Status",
          key: "processado",
        },
        {
          label: "Usuário",
          key: "creator.nome",
        },
        {
          label: "Data",
          key: "created_at",
        },
      ],
      arquivo: null,
      items: [],
      loadingTable: false,
      importing: false,
      tags: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loadingTable = true;
      const promise = ImportService.getAll({ tipo: "medico" });
      return promise
        .then((response) => {
          this.items = response.data || [];
        })
        .then(() => {
          this.loadingTable = false;
        });
    },
    uploadCsv() {
      if (this.arquivo == null) return;

      this.importing = true;
      let formData = new FormData();
      formData.append("arquivo", this.arquivo);
      formData.append("tipo", "medico");

      const promise = ImportService.uploadCsv(formData);

      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
        })
        .catch(() => { })
        .then(() => {
          this.importing = false;
          this.arquivo = null;
          this.loadData();
        });
    },
    downloadErros(id, arquivo) {
      this.exporting = true;
      const promise = ImportService.downloadErros(id);
      return promise
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = arquivo;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => { })
        .then(() => {
          this.exporting = false;
        });
    },
  },
};
</script>


<style lang="scss">
.medico__import {
  .form-group label {
    display: flex;
    align-items: center;
  }
}
</style>