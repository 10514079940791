import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/imports";

class ImportService {

    getAll(params) {
        return axios.get(API_URL, {
            params:params,
            headers: authHeader()
        });
    }

    uploadCsv(params) {
        return axios.post(API_URL, params, {
            headers: {
                ...authHeader(),
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    downloadErros(id) {
        return axios.get(API_URL + '/downloadErros/' + id,  {
            responseType: 'arraybuffer',
            headers: authHeader()
        });
    }
}

export default new ImportService();
